import { render, staticRenderFns } from "./BookmarkIconButton.htm?vue&type=template&id=02391d20&scoped=true&"
import script from "./BookmarkIconButton.ts?vue&type=script&lang=js&"
export * from "./BookmarkIconButton.ts?vue&type=script&lang=js&"
import style0 from "./BookmarkIconButton.scss?vue&type=style&index=0&id=02391d20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02391d20",
  null
  
)

export default component.exports