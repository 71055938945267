import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import { BookmarkTypes } from '@/constants/bookmarks/bookmarkType'
import Bookmark from '@/models-ts/bookmarks/Bookmark'
import notifiableRequest from '@/utils-ts/notifiableRequest'

export default Vue.extend<any, any, any, any>({
  props: {
    id: Number,
    type: Number as PropType<BookmarkTypes>,
  },
  data () {
    return {
      saving: false,
    }
  },
  computed: {
    ...mapState({
      bookmarks: (state: any) => state.bookmarks?.bookmarks.value,
      isLoaded: (state: any) => state.bookmarks?.bookmarks.isLoaded,
      isLoggedIn: (state: any) => state.app.authorized,
    }),
    bookmark () {
      return this.bookmarks.find((b: Bookmark) => b.entityId === this.id)
    },
    saved () {
      return this.isLoaded && this.bookmark
    },
    entityType () {
      switch (this.type) {
        case BookmarkTypes.GIG: return 'Gig'
        case BookmarkTypes.JOB: return 'Job'
        case BookmarkTypes.VACANCY: return 'Full-time Job'
      }
    },
    redirectUrl () {
      const lastPart = this.type === BookmarkTypes.GIG
        ? 'gigs'
        : this.type === BookmarkTypes.JOB
          ? 'jobs'
          : 'vacancies'
      return `${process.env.VUE_APP_FRONTEND_URL}/bookmarks/${lastPart}`
    }
  },
  methods: {
    ...mapActions({
      addBookmark: 'bookmarks/addBookmark',
      removeBookmark: 'bookmarks/removeBookmark',
      openModal: 'ui/openModal',
    }),
    async onSaveClick () {
      if (!this.isLoggedIn) {
        this.openModal({
          component: 'lx-sign-up-modal',
          props: {
            fromType: 'bookmark',
            meta: {
              redirect_url: this.redirectUrl,
              bookmark: {
                entityId: this.id,
                type: this.type,
              },
            }
          }
        })
        return
      }
      try {
        this.saving = true
        await notifiableRequest({
          request: () => this.addBookmark({ id: this.id, type: this.type }),
          title: 'Save bookmark',
          successText: `${this.entityType} added to your favourites list.`,
          failureText: `Error submitting request. Please try again.`
        })
      } finally {
        this.saving = false
      }
    },
    async onRemoveClick () {
      try {
        this.saving = true
        await notifiableRequest({
          request: () => this.removeBookmark({ id: this.bookmark.id, type: this.type }),
          title: 'Remove bookmark',
          successText: `${this.entityType} removed from your favourites list.`,
          failureText: `Error submitting request. Please try again.`
        })
      } finally {
        this.saving = false
      }
    },
  }
})
